import React, { useState, useEffect } from "react";
import { Card, Button, ProgressBar } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import FileUploadStatus from "./FileUploadStatus";
import { YESNO } from "../../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { getAll } from "../../store/LibrarySlice";
import { useDispatch } from "react-redux";

const CHUCK_SIZE = 1024 * 1024 * 1;
const LibraryUploadSection = (props) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  // const [setFilesUploaded] = useState([]);
  const { onClose } = props;
  const { getRootProps, getInputProps } = useDropzone({
	  accept: {"image/jpeg":[],'image/png':[]},
    onDrop: (acceptedFiles) => {
      setFiles([...acceptedFiles]);
    },
    onDrop2: (acceptedFiles) => {
      console.log(acceptedFiles);
      const promises2 = [...acceptedFiles].map((acceptedFile) => {
        return new Promise((resolve, reject) => {
          const fileChunks = [];
          let index = 0;

          for (let cur = 0; cur < acceptedFile.size; cur += CHUCK_SIZE) {
            fileChunks.push({
              filename: acceptedFile.name,
              hash: index++,
              chunk: acceptedFile.slice(cur, cur + CHUCK_SIZE),
            });
          }
          const promises = fileChunks.map((fileChunk, progress) => {
            let formData = new FormData();
            formData.append("filename", acceptedFile.name);
            formData.append("hash", fileChunks[progress].hash);
            formData.append("chunk", fileChunks[progress].chunk);
            return axios.post("/api/media/upload", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            });
          });
          Promise.all(promises).then(async () => {
            const r1 = await axios({
              method: "get",
              url: "/api/media/upload/merge",
              params: {
                filename: acceptedFile.name,
              },
            });

            // expect response.status === 201
            // const mediaId = response.data.mediaId;

            const body = {
              ...r1.data,
              path: acceptedFile.path,
              size: acceptedFile.size,
              type: acceptedFile.type,
            };

            /* I don't want the response from this api call. I want to forward the body to the next promise statement */
            const r2 = await axios({
              method: "post",
              url: "/api/media/upload/register",
              data: body,
            });

            const r3 = await axios({
              method: "post",
              url: "/api/media/upload/generateThumb",
              data: body,
            });
          });
          resolve();
        });
      });
      console.log(78);
      Promise.all(promises2).then(() => {
        console.log(90);
        setTimeout(() => {
          dispatch(getAll());
        }, 2000);
      });
    },
    // setFiles([...acceptedFiles]);
    //
  });

  const handleClose = () => {
    onClose && onClose();
  };
  return (
    <Card>
      <Card.Header>
        <div className="d-flex justify-content-between">
          <div />
          <div style={{ cursor: "pointer" }} onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <section className="container">
          <div {...getRootProps({ className: "dropzone " })}>
            <input {...getInputProps()} />

            <div className="h3">
              Drop files here or click <Button>Select Files</Button> to upload.
            </div>
          </div>
        </section>
      </Card.Body>

      <Card.Footer>
        {files.map((file, i) => {
          return <FileUploadStatus key={i} file={file} />;
        })}
      </Card.Footer>
    </Card>
  );
};
export default LibraryUploadSection;
