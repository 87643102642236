import React from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Forbidden from "../pages/Forbidden";
import store from "../store/store";
import { setCurrentUser, clearCurrentUser } from "../store/ApplicationSlice";

const url = "https://auth2.hslnet.my/auth/realms/";
const realm = "hsltower";
const client_id = `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`;
const REFRESH_RATE = 5;
let access_token = "";
export let parsed_token = {};
// let parsed_decoded_header = {};

const doLogin = (username, password) => {
  const params = new URLSearchParams();
  params.append("grant_type", "password");
  params.append("client_id", client_id);
  params.append("username", username);
  params.append("password", password);
  return new Promise((resolve, reject) => {
    axios
      .post(url + realm + "/protocol/openid-connect/token", params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        /* for future reference */
        // parsed_decoded_header = jwt_decode(res.data?.access_token, {
        //   header: true,
        // });

        access_token = res.data?.access_token;
        parsed_token = jwt_decode(res.data?.access_token);

        localStorage.setItem("access_token", res.data?.access_token);
        localStorage.setItem("refresh_token", res.data?.refresh_token);
        resolve(parsed_token);
      })
      .catch((e) => {
        console.log(e);
        store.dispatch(clearCurrentUser());
        localStorage.setItem("access_token", "");
        localStorage.setItem("refresh_token", "");
        reject(e);
      });
  });
};
const doLogout = () => {
  store.dispatch(clearCurrentUser());
  localStorage.setItem("refresh_token", "");
  localStorage.setItem("access_token", "");
};

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const init = (onAuthenticatedCallback) => {
  onAuthenticatedCallback && onAuthenticatedCallback();
  refreshAccessToken();
  window.setInterval(() => {
    refreshAccessToken();
  }, REFRESH_RATE * 1000);
};

const refreshAccessToken = () => {
  const thisLocation = window.location.href;
  if (thisLocation.includes("172.16.170")) {
    return;
  }
  const params = new URLSearchParams();
  let refresh_token = localStorage.getItem("refresh_token");

  if (refresh_token === undefined || refresh_token === "") {
    // console.log("Not Login");

    // if (window.location.href.indexOf("login") === -1) {
    //   window.location = "/login";
    // }
    if (window.location.href.indexOf("library") !== -1) {
      window.location = "/login";
    }
  }
  params.append("grant_type", "refresh_token");
  params.append("client_id", client_id);
  params.append("refresh_token", refresh_token);

  axios
    .post(
      "https://auth2.hslnet.my/auth/realms/" +
        realm +
        "/protocol/openid-connect/token",
      params,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
    .then((res) => {
      access_token = res.data?.access_token;
      // parsed_decoded_header = jwt_decode(res.data?.access_token, {
      //   header: true,
      // });
      parsed_token = jwt_decode(res.data?.access_token);

      store.dispatch(setCurrentUser(parsed_token));
      localStorage.setItem("access_token", res.data?.access_token);
      localStorage.setItem("refresh_token", res.data?.refresh_token);
    })
    .catch((e) => {
      // console.log(e);
      localStorage.setItem("access_token", "");
      localStorage.setItem("refresh_token", "");
      if (refresh_token === undefined || refresh_token === "") {
        console.log("Not Login");

        if (window.location.href.indexOf("login") === -1) {
          // window.location = "/login";
        }
      }
    });
};

const hasRole = (roles) => {
  // return true;
  return roles?.some((role) =>
    parsed_token?.realm_access?.roles?.includes(role)
  );
};

export const RenderOnRole = (props) => {
  return hasRole(props?.roles) ? props.children : <></>;
};
export const ProtectedElement = (props) => {
  return hasRole(props?.roles) ? props.children : <></>;
};
export const ProtectedRoute = (props) => {
  return hasRole(props?.roles) ? props.children : <Forbidden />;
};
export const getToken = () => {
  return access_token;
};
const UserService = {
  init,
  doLogin,
  doLogout,
  hasRole,

  ProtectedElement,
  ProtectedRoute,
  getToken,
};

export default UserService;
