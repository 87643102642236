import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "./pages/Login/Login";
import MainPage from "./pages/MainPage/MainPage";

// import CompanyForm from "./pages/CompanyForm/CompanyMain";
// import DepartmentForm from "./pages/DepartmentForm/DepartmentMain";
// import AssignAppraiserForm from "./pages/AssignAppraiserForm/AssignAppraiserForm";
// import SupervisorAppraisalListMain from "./pages/SupervisorAppraisalListForm/SupervisorAppraisalListMain";
// import ImportUserForm from "./pages/ImportUserForm/ImportUserMain";
// import DepartmentSubmitted from "./pages/Stats/DepartmentSubmitted";
import AppContainer from "./components/AppContainer";
import Library from "./pages/Library/Library";
// import DepartmentScore from "./pages/Stats/DepartmentScore";
// import CompanyScore from "./pages/Stats/CompanyScore";
// import AppraiseeScore from "./pages/AppraiseeScore/AppraiseeScore";
// import AppraiseeReview from "./pages/AppraiseeReview/AppraiseeReview";
import NotFound from "./pages/NotFound";
// import Foobar from "./pages/Foobar";

import { useSelector } from "react-redux";
import { ProtectedRoute } from "./services/UserService";

import "./App.css";

const App = () => {
  useEffect(() => {
    document.title = "ShowTime";
  }, []);
  const loggedIn = useSelector((state) => state.ApplicationSlice.loggedIn);
  return (
    <AppContainer showControl={loggedIn}>
      <Router>
        <Routes>
          {/*           
          
          <Route
            path="/AssignAppraiser"
            element={
              <ProtectedRoute roles={["appraisal_hr"]}>
                <AssignAppraiserForm />
              </ProtectedRoute>
            }
          /> */}

          <Route
            path="/library"
            element={
              <ProtectedRoute roles={["lapromenade_tv"]}>
                <Library />
              </ProtectedRoute>
            }
          />

          <Route path="/library" element={<Library />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<MainPage />} />
          <Route path="*" element={<NotFound />} />
          {/* <Route path="*" element={<MainPage />} /> */}
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </Router>
    </AppContainer>
  );
};

export default App;
