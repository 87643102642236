import React from "react";
import { connect } from "react-redux";
import { YESNO } from "../Constants";
import { clearAllErrors } from "../store/ApplicationSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faDoorClosed } from "@fortawesome/free-solid-svg-icons";
import { clearCurrentUser } from "../store/ApplicationSlice";
import UserService from "../services/UserService";

import logo from "../assets/logo2.png";

const mapStateToProps = (state, ownProps) => {
  const errors = state.ApplicationSlice.errors;
  const errFlag = state.ApplicationSlice.errFlag;
  const showControl = ownProps.showControl;
  const givenName = state.ApplicationSlice.currentUser?.given_name;

  return { errFlag, errors, showControl, givenName };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  const clear = () => dispatch(clearAllErrors());
  const _clearCurrentUser = () => dispatch(clearCurrentUser());
  return { clearAllErrors: clear, clearCurrentUser: _clearCurrentUser };
};
const AppContainer = (props) => {
  const { showControl, errFlag, errors, clearAllErrors, givenName } = props;

  const handleLogout = (ev) => {
    if (window.confirm("Confirm Logout")) {
      new Promise((resolve) => {
        UserService.doLogout();
        resolve();
      }).then(() => {
        window.location = "../Login";
      });
    }
  };
  const handleGotoMain = (ev) => {
    if (window.confirm("Confirm return to main page?")) {
      window.location = "/library";
    }
  };
  const handleClearAllErrors = () => {
    clearAllErrors();
  };

  return (
    <div>
      {showControl === YESNO.YES && (
        <div className="card">
          <div
            className="card-header"
            style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
          >
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <span className="me-2" onClick={handleGotoMain}>
                  <img alt="logo" className="logo" src={logo} />
                </span>
              </div>

              <div>
                <span className="logoutButton" onClick={handleLogout}>
                  {givenName} <FontAwesomeIcon icon={faTimes} />
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {errFlag && (
        <div className="card" style={{ margin: "2em" }}>
          <div className="card-header">
            <h3>Error encountered:</h3>
          </div>
          <div className="card-body bg-warning">
            {errors.map((e, i) => {
              return <div key={i}>{e}</div>;
            })}
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-between">
              <div></div>
              <div>
                <button className="btn-danger" onClick={handleClearAllErrors}>
                  Clear Errors
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>{props.children}</div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
