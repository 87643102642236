import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import axios from "axios";
import noThumbnail from "../../assets/noThumbnail.png";

const MediaItem = (props) => {
  const { media, onClose } = props;
  let { mediaId, month, year, path, size, type, description } = media;
  const [image, setImage] = useState(noThumbnail);
  description =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum";
  useEffect(() => {
    console.log(media);
    console.log("/api/medium/thumbnail/media/" + media.mediaId);
    axios.get("/api/medium/thumbnail/media/" + mediaId).then((res) => {
      setImage(res.data);
    });
  }, [media]);

  const handleClose = (ev) => {
    onClose && onClose();
  };
  return (
    <Card>
      <Card.Header>
        <div className="d-flex justify-content-between">
          <div>Media Item</div>
          <div className="link" onClick={handleClose}>
            X
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={4} className="d-xs-none d-md-block">
            <img className="img-fluid" src={image} alt="" />
          </Col>
          <Col md={8}>
            <Form.Group className="row">
              <Form.Label className="col-4">ID</Form.Label>
              <div className="col-8">
                <p>{mediaId}</p>
              </div>
            </Form.Group>

            <Form.Group className="row">
              <Form.Label className="col-4">Name</Form.Label>
              <div className="col-8">
                <Form.Control as="input" value={path} />
              </div>
            </Form.Group>

            <Form.Group className="row">
              <Form.Label className="col-4">Size</Form.Label>
              <div className="col-8">
                <p>{size}</p>
              </div>
            </Form.Group>

            <Form.Group className="row">
              <Form.Label className="col-4">Type</Form.Label>
              <div className="col-8">
                <p>{type}</p>
              </div>
            </Form.Group>

            <Form.Group className="row">
              <Form.Label className="col-4">Description</Form.Label>
              <div className="col-8">
                <Form.Control as="textarea" rows="5" value={description} />
              </div>
            </Form.Group>
            <Form.Group className="row">
              <div className="clickToDelete">
                Click to delete this permanently
              </div>
            </Form.Group>

            <Form.Group className="row">
              <div className="d-flex justify-content-between">
                <div />
                <div>
                  <Button>Update</Button>
                </div>
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default MediaItem;
