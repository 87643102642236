import React, { useEffect, useState } from "react";
import axios from "axios";
import TimeAgo from "react-timeago";
import englishStrings from "react-timeago/lib/language-strings/en";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
const formatter = buildFormatter(englishStrings);
const LibraryFooter = (props) => {
  // https://ad2.hslnet.my/api/log
  const [msg, setMsg] = useState("");
  const [msg2, setMsg2] = useState({
    serverStatus: "",
    serverTime: new Date(),
  });
  // const checkLog = (oldMsg) => {
  //   axios
  //     .get("api/log")
  //     .then((res) => {
  //       // console.log(res.data.message);

  //       let newMessage = res.data.message;
  //       if (newMessage !== oldMsg) {
  //         setMsg(newMessage);
  //       } else {
  //         // console.log(14);
  //       }
  //     })
  //     .catch((e) => {});
  // };
  const checkLog2 = (oldMsg) => {
    axios
      .get("api/log2")
      .then((res) => {
        const serverStatus = res.data.status;
        const serverTime = new Date(res.data.seqno);

        setMsg2({
          serverStatus: serverStatus,
          serverTime: serverTime,
        });
      })
      .catch((e) => {});
  };
  // useEffect(() => {
  //   const timer = setInterval(() => checkLog(msg), 250);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);
  useEffect(() => {
    const timer2 = setInterval(() => checkLog2(msg2), 250);
    return () => {
      clearInterval(timer2);
    };
  }, []);
  const handleSetFree = (evt) => {
    axios.post("api/free").then(() => {});
  };
  return (
    <div className="d-flex justify-content-between">
      <div>
        {msg2.serverStatus !== "FREE" && (
          <span onClick={handleSetFree} style={{ cursor: "pointer" }}>
            Busy since <TimeAgo date={msg2.serverTime} formatter={formatter} />
          </span>
        )}
      </div>
      {/* <div>{msg}</div> */}
      <div>Version 1.0</div>
    </div>
  );
};

export default LibraryFooter;
