import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  libraryItems: [],
 
};
export const getAll = createAsyncThunk("library/getall", async () => {
  const { data } = await axios.get("/api/medium/all");
  console.log(data);
  return data;
});
 
export const updateMedium = createAsyncThunk(
  "library/updateMedium",
  async (body) => {
    const { data } = await axios.patch("/api/medium", body);

    return data.filter((media) => {
      return media.status !== "deleted";
    });
  }
);
export const updateAndPlayMedium = createAsyncThunk(
  "library/updateMedium",
  async (body) => {
    const { data } = await axios.patch("/api/medium", body);
    await axios.post("/api/playMedium");
    return data.filter((media) => {
      return media.status !== "deleted";
    });
  }
);
const slice = createSlice({
  name: "LibrarySlice",
  initialState: initialState,
  reducers: {
    foobar: (state, action) => {
      return state;
    },
    loadLibraryItems: (state, action) => {
      return { ...state, libraryItems: action.payload };
    },
  },
  extraReducers: {
    [getAll.pending](state) {},
    [getAll.fulfilled](state, action) {
      // console.log(action.payload);
      state.libraryItems = action.payload;
    },
    

    [updateMedium.pending](state) {},
    [updateMedium.fulfilled](state, action) {
      state.libraryItems = action.payload;
    },
    [updateAndPlayMedium.pending](state) {},
    [updateAndPlayMedium.fulfilled](state, action) {
      state.libraryItems = action.payload;
    },
  },
});

export const { foobar, loadLibraryItems } = slice.actions;
export default slice.reducer;
