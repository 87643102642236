import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import LibraryHeader from "./LibraryHeader";

import LibraryFooter from "./LibraryFooter";
import LibraryItemList from "./LibraryItemList";
import LibraryUploadSection from "./LibraryUploadSection";
import MediaItem from "./MediaItem";
import {
  getAll,
  postAll,
  loadLibraryItems,
  updateMedium,
  updateAndPlayMedium,
} from "../../store/LibrarySlice";
import { YESNO } from "../../Constants";

const mstp = (state, ownProps) => {
  const libraryItems = state.LibrarySlice.libraryItems;

  return { libraryItems };
};
const Library = (props) => {
  const { libraryItems } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    // axios.get("/api/medium/all").then((res) => {
    //   dispatch(loadLibraryItems(res.data));
    // });
    //dispatch(postAll());
    dispatch(getAll());
  }, []);

  const [bulkSelectMode, setBulkSelectMode] = useState(true);
  const handleBulkSelectOn = (ev) => {
    setBulkSelectMode(true);
  };

  const [showUploadSection, setShowUploadSection] = useState(false);
  const [showMediaDetail, setShowMediaDetail] = useState(false);
  const [media, setMedia] = useState({});

  const handleShowAddContainer = (ev) => {
    setShowUploadSection(!showUploadSection);
  };

  const handleHideAddContainer = () => {
    console.log(49);
    setShowUploadSection(false);
  };

  const handleSelectMedia = (selectedMedia) => {
    if (bulkSelectMode) {
      const newLibraryItems = libraryItems.map((item, i) => {
        if (item.mediaId === selectedMedia.mediaId) {
          const isSelected =
            selectedMedia.isSelected === YESNO.YES ? YESNO.NO : YESNO.YES;

          return { ...item, isSelected: isSelected };
        } else {
          return item;
        }
      });
      dispatch(loadLibraryItems(newLibraryItems));
    } else {
      setShowMediaDetail(true);
      setMedia(selectedMedia);
    }
  };

  const handlePlaySelected = () => {
    const newLibraryItems = libraryItems.map((item, i) => {
      if (item.isSelected === YESNO.YES) {
        return { ...item, isPlaying: YESNO.YES };
      } else {
        return { ...item, isPlaying: YESNO.NO };
      }
    });

    dispatch(updateAndPlayMedium(newLibraryItems));
    // setBulkSelectMode(false);
  };

  const handleDeleteSelected = () => {
    if (window.confirm("Confirm Delete Selected?")) {
      const newLibraryItems = libraryItems.map((item, i) => {
        if (item.isSelected === YESNO.YES) {
          return { ...item, status: "deleted" };
        } else {
          return { ...item, status: "" };
        }
      });

      dispatch(updateMedium(newLibraryItems));
    }
  };
  const handleCancelSelected = () => {
    const newLibraryItems = libraryItems.map((item, i) => {
      return { ...item, isSelected: YESNO.NO };
    });
    dispatch(loadLibraryItems(newLibraryItems));
    setBulkSelectMode(false);
  };
  const handleListMedia = () => {
    setShowMediaDetail(false);
  };
  return (
    <Card>
      <Card.Body>
        <div className="d-flex flex-row">
          <div className="p-1">
            <h1>Media Library</h1>
          </div>
          <div className="p-1">
            <Button onClick={handleShowAddContainer}>Add new</Button>
          </div>
        </div>
      </Card.Body>
      {showUploadSection && (
        <Card.Body>
          <LibraryUploadSection onClose={handleHideAddContainer} />
        </Card.Body>
      )}

      <Card.Body>
        {showMediaDetail === true && (
          <>
            <MediaItem media={media} onClose={handleListMedia} />
          </>
        )}
        {showMediaDetail === false && (
          <>
            <LibraryItemList
              records={libraryItems}
              onSelect={handleSelectMedia}
            />
          </>
        )}
      </Card.Body>
      <Card.Body>
        <LibraryHeader
          bulkSelectMode={bulkSelectMode}
          onBulkSelect={handleBulkSelectOn}
          onPlaySelected={handlePlaySelected}
          onDeleteSelected={handleDeleteSelected}
          onCancelSelected={handleCancelSelected}
        ></LibraryHeader>
      </Card.Body>
      <Card.Footer>
        <LibraryFooter />
      </Card.Footer>
    </Card>
  );
};
export default connect(mstp, null)(Library);
