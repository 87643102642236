import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
// import VideoJS from "../../components/VideoJS";
import ReactHlsPlayer from "react-hls-player";
// import moment from "moment";

const MainPage = (props) => {
  const [src, setSrc] = useState("");
  const playerRef = useRef();
  const getApiUrl = () => {
    const thisLocation = window.location.href;
    // console.log(thisLocation);
    if (thisLocation.includes("172.16.170")) {
      return "http://172.16.170.5:5000/";
    } else {
      return thisLocation + "/api/";
    }
  };
  const getStreamUrl = () => {
    const thisLocation = window.location.href;
    if (thisLocation.includes("172.16.170")) {
      return thisLocation;
    } else {
      return thisLocation + "/stream/";
    }
  };
  const chkStream1 = () => {
    axios.get(getApiUrl() + "getStream").then((res) => {
      // console.log(res);
      if (res.data?.stream) {
        const newStream = res.data.stream;
        console.log(getStreamUrl() + "/stream/" + newStream);
        setSrc(getStreamUrl() + "/stream/" + newStream);
      }
    });
  };

  const chkStream = (currentSrc) => {
    axios.get(getApiUrl() + "getStream").then((res) => {
      if (res.data?.stream) {
        const newStream = getStreamUrl() + "/stream/" + res.data.stream;
        if (newStream !== currentSrc) {
          window.location = "/";
        }
      }

      return;
    });
  };

  useEffect(() => {
    const timer = setInterval(() => {
      chkStream(src);
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [src]);
  useEffect(() => {
    const timer = setInterval(() => {
      playerRef.current.play();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [src]);
  useEffect(() => {
    chkStream1();
  }, []);
  useEffect(() => {
    const fireOnVideoEnd = () => {
      playerRef.current.play();
    };
    playerRef.current.addEventListener("ended", fireOnVideoEnd);
    // return playerRef.current.removeEventListener('ended', fireOnVideoEnd);
  });

  return (
    <>
      <ReactHlsPlayer
        playerRef={playerRef}
        src={src}
        type={"application/x-mpegURL"}
        autoPlay={true}
        controls={true}
        muted={true}
        // loop={true}
        width="100%"
        height="auto"
      />
    </>
  );
};
export default MainPage;
