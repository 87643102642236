import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import LibraryItem from "./LibraryItem";
const LibraryItemList = (props) => {
  const { onSelect, records } = props;
  const handleSelectMedia = (media) => {
    onSelect && onSelect(media);
  };
  return (
    <Row>
      {records?.map((record, i) => {
        return (
          <Col xs={12} sm={6} md={3} lg={2} key={i}>
            <div className="LibaryItem">
              <LibraryItem record={record} onSelect={handleSelectMedia} />
            </div>
          </Col>
        );
      })}
    </Row>
  );
};
export default LibraryItemList;
