import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Button } from "react-bootstrap";

const LibraryHeader = (props) => {
  const {
    bulkSelectMode,
    onBulkSelect,
    onPlaySelected,
    onDeleteSelected,
    onCancelSelected,
  } = props;
  const handleBulkSelectOn = (ev) => {
    onBulkSelect && onBulkSelect();
  };

  const handleDeleteSelected = (ev) => {
    onDeleteSelected && onDeleteSelected();
  };
  const handlePlaySelected = (ev) => {
    onPlaySelected && onPlaySelected();
  };

  const handleCancelSelected = (ev) => {
    onCancelSelected && onCancelSelected();
  };
  return (
    <div className="d-flex justify-content-between">
      <div>
        {bulkSelectMode === false && (
          <div className="d-flex flex-row">
            {/* <div className="p-2">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                All Dates
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">All Date</Dropdown.Item>
                <Dropdown.Item href="#/action-2">October 2022</Dropdown.Item>
                <Dropdown.Item href="#/action-3">September 2022</Dropdown.Item>
                <Dropdown.Item href="#/action-3">August 2022</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            </div> */}
            <div className="p-2">
              <Button onClick={handleBulkSelectOn}>Bulk Select</Button>
            </div>
          </div>
        )}
        {bulkSelectMode === true && (
          <Button variant="danger" onClick={handleDeleteSelected}>
            Delete permanently
          </Button>
        )}
      </div>
      <div>
        {bulkSelectMode === true && (
          <>
            {/* <Button
              className="me-1"
              variant="warning"
              onClick={handleCancelSelected}
            >
              Cancel
            </Button> */}
            <Button variant="success" onClick={handlePlaySelected}>
              Play Selected
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default LibraryHeader;
