import React, { useState, useEffect } from "react";
import { Row, Col, ProgressBar } from "react-bootstrap";
import { getAll } from "../../store/LibrarySlice";
import { useDispatch } from "react-redux";
import axios from "axios";
/* 100KB Section size */
const CHUCK_SIZE = 1024 * 100;
// const CHUCK_SIZE = 1024 * 50;
const FileUploadStatus = (props) => {
  const dispatch = useDispatch();
  const { file } = props;

  const [progress, setProgress] = useState(-1);
  const [completed, setCompleted] = useState(false);
  const [fileChunks, setFileChunks] = useState([]);

  const doPost = async () => {
    const r1 = await axios({
      method: "get",
      url: "/api/media/upload/merge",
      params: {
        filename: file.name,
      },
    });

    // expect response.status === 201
    // const mediaId = response.data.mediaId;

    const body = {
      ...r1.data,
      path: file.path,
      size: file.size,
      type: file.type,
    };

    /* I don't want the response from this api call. I want to forward the body to the next promise statement */
    const r2 = await axios({
      method: "post",
      url: "/api/media/upload/register",
      data: body,
    });

    const r3 = await axios({
      method: "post",
      url: "/api/media/upload/generateThumb",
      data: body,
    });
    setTimeout(() => {
      setCompleted(true);
      dispatch(getAll());
    }, 500);
  };

  useEffect(() => {
    if (fileChunks.length === 0) return;
    const uploadNext = async () => {
      if (progress >= fileChunks.length) {
        await doPost();
        return;
      }

      let formData = new FormData();

      formData.append("filename", file.name);
      formData.append("hash", fileChunks[progress].hash);
      formData.append("chunk", fileChunks[progress].chunk);

      await axios.post("/api/media/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setProgress(progress + 1);
    };
    uploadNext();
  }, [progress]);

  useEffect(() => {
    setCompleted(false);
    setProgress(-1);
    const timer = setTimeout(() => {
      // console.log(file);
      if (!file) return;
      const newFileChunks = [];
      let index = 0;

      for (let cur = 0; cur < file.size; cur += CHUCK_SIZE) {
        newFileChunks.push({
          filename: file.name,
          hash: index++,
          chunk: file.slice(cur, cur + CHUCK_SIZE),
        });
      }

      setProgress(0);
      setFileChunks(newFileChunks);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [file]);

  return (
    <>
      {!completed && (
        <Row>
          <Col xs={6} sm={6} md={4} lg={3}>
            {file.name}
          </Col>
          <Col xs={6} sm={6} md={4} lg={3}>
            <ProgressBar
              striped
              variant="success"
              now={+progress}
              max={+fileChunks.length}
            />
            {completed && <span>OK</span>}
          </Col>
        </Row>
      )}
    </>
  );
};
export default FileUploadStatus;
