import React, { useEffect, useState, useCallback } from "react";

import { Row, Col } from "react-bootstrap";
import axios from "axios";
import noThumbnail from "../../assets/noThumbnail.png";
import { YESNO, NOTFOUNDPIC } from "../../Constants";
import playButton from "../../assets/playButton.png";
const LibraryItem = (props) => {
  const [image, setImage] = useState(noThumbnail);
  const { onSelect, record } = props;
  const { mediaId, type, isPlaying, isSelected } = record;
  const [reload, setReload] = useState(1);
  // console.log(type);
  const getThumbnail = useCallback(
    (mediaId) => {
      axios
        .get("/api/medium/thumbnail/media/" + mediaId)
        .then((res) => {
          if (res.data === "") {
            setImage(NOTFOUNDPIC);
            setTimeout(() => {
              console.log(22);
              setReload(reload + 1);
            }, 2000);
          } else {
            setImage(res.data);
          }
        })
        .catch((e) => {
          setImage(NOTFOUNDPIC);
          setTimeout(() => {
            console.log(32);
            setReload(reload + 1);
          }, 2000);
        });
    },
    [reload, setReload]
  );

  useEffect(() => {
    getThumbnail(mediaId);

    // axios.get("/api/medium/thumbnail/media/" + mediaId).then((res) => {
    //   setImage(res.data);
    // });
  }, [mediaId, reload, getThumbnail]);
  const handleClick = (ev) => {
    // console.log(record);

    onSelect && onSelect(record);
  };
  const className =
    isSelected === YESNO.YES ? "mediaContainer isSelected" : "mediaContainer";
  return (
    <>
      <div className={className}>
        <img className="img-fluid" src={image} alt="" onClick={handleClick} />

        {isPlaying === YESNO.YES && (
          <div className="playButton">
            <img height="24px" src={playButton} alt="isPlaying" />
          </div>
        )}
      </div>
    </>
  );
};
export default LibraryItem;
