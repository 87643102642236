import { createSlice } from "@reduxjs/toolkit";
import { YESNO } from "../Constants";

const initialState = {
  currentUser: {},
  errFlag: false,
  lastErrorMsg: "",
  errors: [],
  applicationFormIncomplete: false,
  loggedIn: YESNO.NO,
};

const slice = createSlice({
  name: "ApplicationSlice",
  initialState: initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      return { ...state, currentUser: action.payload, loggedIn: YESNO.YES };
    },
    clearCurrentUser: (state, action) => {
      console.log("here");
      return { ...state, currentUser: {}, loggedIn: YESNO.NO };
    },
    clearAllErrors: (state, action) => {
      return { ...state, errFlag: false, lastErrorMsg: "", errors: [] };
    },
    clearLastError: (state, action) => {
      const newErrors = state.errors.filter((currentValue, index, arr) => {
        return index !== arr.length - 1;
      });
      if (newErrors.length > 0) {
        const lastErrorMsg = newErrors[newErrors.length - 1];
        return {
          ...state,
          errFlag: true,
          lastErrorMsg: lastErrorMsg,
          errors: newErrors,
        };
      } else {
        return {
          ...state,
          errFlag: false,
          lastErrorMsg: "",
          errors: newErrors,
        };
      }
    },
    postError: (state, action) => {
      const newErrors = [...state.errors];
      const e = action.payload;
      console.log(e);
      if (e.hasOwnProperty("response") && e.response.hasOwnProperty("data")) {
        newErrors.push(e.response.data);
      } else if (e.hasOwnProperty("message")) {
        newErrors.push(e.message);
      } else {
        newErrors.push(String(e));
      }

      return {
        ...state,
        errFlag: true,
        lastErrorMsg: action.payload,
        errors: newErrors,
      };
    },
    setApplicationFormIncomplete: (state, action) => {
      return { ...state, applicationFormIncomplete: action.payload };
    },
  },
});

export const {
  setCurrentUser,
  clearCurrentUser,
  clearAllErrors,
  clearLastError,
  postError,
  setApplicationFormIncomplete,
} = slice.actions;
export default slice.reducer;
